<template>
    <PageContainer>
        <h5>Help & FAQ</h5>
        <br />
        <Loader :isLoading="isLoading" />
        <div class="row">
            <div class="col-md-7">
                <div v-if="isLoading">Please wait...<br /></div>
                <div v-for="(faqItem, i) in faq" :key="faqItem.id">
                    <div id="accordion">
                        <div class="card mb-3">
                            <div class="card-header p-0" :id="'faqitemheading' + faqItem.id">
                                <h5 class="mb-0">
                                    <button
                                        class="accordion-item-header d-block text-left w-100"
                                        data-toggle="collapse"
                                        :data-target="'#faqitem' + faqItem.id"
                                        aria-expanded="false"
                                        aria-controls="collapseOne"
                                    >
                                        {{ i + 1 }}. {{ faqItem.question }}
                                    </button>
                                </h5>
                            </div>

                            <div
                                :id="'faqitem' + faqItem.id"
                                class="collapse"
                                :aria-labelledby="'faqitemheading' + faqItem.id"
                                data-parent="#accordion"
                            >
                                <div class="card-body">{{ faqItem.answer }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5" v-if="!isLoading">
                <img class="img-fluid" :src="baseURL + '/public/assets/img/faq.jpg'" alt="faq" />
            </div>
        </div>
        <hr />
        <div style="font-size: 0.8em">
            Didn't find the answer to your query?
            <router-link :to="{ name: 'contact' }" class="text-primary">Contact Us</router-link>
        </div>
    </PageContainer>
</template>

<script>
import PageContainer from "../utils/PageContainer.vue"
import request from "../../apis/request"
import Loader from "../Loader.vue"
import axios from "axios"

export default {
    components: {
        PageContainer,
        Loader,
    },
    data() {
        return {
            faq: [],
            baseURL: axios.defaults.baseURL,
            isLoading: false,
        }
    },
    async mounted() {
        this.isLoading = true
        await this.loadFaq()
        this.isLoading = false
    },
    methods: {
        async loadFaq() {
            try {
                const res = await request.get("/faq-info")
                this.faq = res.data.result
            } catch (err) {
                console.log("error in loading faq", err)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.accordion-item-header {
    border: none;
    font-size: 18px;
    padding: 15px;
    cursor: pointer;
}
</style>
